const bodyEl = document.body;

// Header
const navToggleBtn = document.querySelector('.js-nav-toggle');
const menuEl = document.querySelector('.js-menu');
const overlayEl = document.querySelector('.js-nav-overlay');
navToggleBtn.addEventListener('click', () => {
  bodyEl.classList.toggle('scroll-disabled');
  navToggleBtn.classList.toggle('open');
  menuEl.classList.toggle('open');
  overlayEl.classList.toggle('open');
});

// Footer
const accordionToggleBtn = document.querySelector('.js-accordion-toggle');
const accordionIcon = document.querySelector('.js-accordion-icon');
const accordionEl = document.querySelector('.js-accordion');
accordionToggleBtn.addEventListener('click', () => {
  accordionToggleBtn.classList.toggle('open');
  accordionIcon.classList.toggle('open');
  accordionEl.classList.toggle('open');
});
